import React from 'react';
import PagiList from "../list/PagiList";
import Sort from "./DeviceListCompareFunctions";
import '!style-loader!css-loader!./main.css';
import {StatusField, GpsField, GroupField, LoanField, InuseField} from './CustomizeField';
import API from "../API"
import I from "../main/Initialize"
import Checkbox from '../device/Checkbox';

//with RMS
const columuns_desktop_rms = [
  {dataField:'checkbox',     text:'',  sort:false},
  {dataField:'sensor_type_name', text:'機器種別',    sort:true, afunc: Sort.compareSensorTypeAsc,     dfunc: Sort.compareSensorTypeDesc },
  {dataField:'deviceId', text:'シリアル',    sort:true, afunc: Sort.compareDeviceAsc,     dfunc: Sort.compareDeviceDesc },
  {dataField:'athlete_name',text:'選手',  sort:true, afunc: Sort.compareAthleteNameAsc,     dfunc: Sort.compareAthleteNameDesc },
  {dataField:'name',     text:'表示名',  sort:true, afunc: Sort.compareNameAsc,     dfunc: Sort.compareNameDesc },
  {dataField:'in_use',  text:'競技用',  sort:true, afunc: Sort.compareInuseAsc,  dfunc: Sort.compareInuseDesc, formatedField: InuseField},
  {dataField:'group_name_and_color',text:'Group', sort:true, afunc: Sort.compareGroupNameAsc,  dfunc: Sort.compareGroupNameDesc, formatedField: GroupField },
  {dataField:'status',   text:'Status',sort:true, afunc: Sort.compareStatusAsc,  dfunc: Sort.compareStatusDesc, formatedField: StatusField },
  {dataField:'access',   text:'Acess',sort:true, afunc: Sort.compareAccessAsc,  dfunc: Sort.compareAccessDesc },
  {dataField:'gps',      text:'GPS',sort:true, afunc: Sort.compareGpsAsc,  dfunc: Sort.compareGpsDesc, formatedField: GpsField },
  {dataField:'battery',  text:'Battery',sort:true, afunc: Sort.compareBatteryAsc,  dfunc: Sort.compareBatteryDesc },
  {dataField:'is_loan',     text:'貸出',sort:true, afunc: Sort.compareLoanAsc,  dfunc: Sort.compareLoanDesc, formatedField: LoanField },
];

//without RMS
const columuns_desktop = [
  {dataField:'checkbox',     text:'',  sort:false},
  {dataField:'sensor_type_name', text:'機器種別',    sort:true, afunc: Sort.compareSensorTypeAsc,     dfunc: Sort.compareSensorTypeDesc },
  {dataField:'deviceId', text:'シリアル',    sort:true, afunc: Sort.compareDeviceAsc,     dfunc: Sort.compareDeviceDesc }, 
  {dataField:'name',     text:'表示名',  sort:true, afunc: Sort.compareNameAsc,     dfunc: Sort.compareNameDesc },
  {dataField:'group_name_and_color',text:'Group', sort:true, afunc: Sort.compareGroupNameAsc,  dfunc: Sort.compareGroupNameDesc, formatedField: GroupField },
  {dataField:'status',   text:'Status',sort:true, afunc: Sort.compareStatusAsc,  dfunc: Sort.compareStatusDesc, formatedField: StatusField },
  {dataField:'access',   text:'Acess',sort:true, afunc: Sort.compareAccessAsc,  dfunc: Sort.compareAccessDesc },
  {dataField:'gps',      text:'GPS',sort:true, afunc: Sort.compareGpsAsc,  dfunc: Sort.compareGpsDesc, formatedField: GpsField },
  {dataField:'battery',  text:'Battery',sort:true, afunc: Sort.compareBatteryAsc,  dfunc: Sort.compareBatteryDesc },
  {dataField:'is_loan',     text:'貸出',sort:true, afunc: Sort.compareLoanAsc,  dfunc: Sort.compareLoanDesc, formatedField: LoanField },
];




const columuns_mobile = [
  {dataField:'deviceId',  text:'ID',    sort:true, afunc: Sort.compareDeviceAsc,     dfunc: Sort.compareDeviceDesc }, 
  {dataField:'name',      text:'Name',  sort:true, afunc: Sort.compareNameAsc,     dfunc: Sort.compareNameDesc },
  {dataField:'show',      text:'Show',  sort:false},
];


var instance;

export default class DeviceList extends React.Component {
  constructor(props){
    super(props);
    instance = this;

    //view
    let w = this.getWindowSize();
    let columuns = columuns_desktop;
    let style = "device-list";
    if(this.props.rms_option){
      columuns = columuns_desktop_rms;
    }
    if(w.width < 500){
      columuns = columuns_mobile;
      style = "device-list-mobile";
    }

    //deviceMap Initialize
    let deviceMap = this.props.deviceMap;
    for(let i in deviceMap){
      deviceMap[i].checkbox = <Checkbox ischecked={this.ischecked} handleCheckboxChange={() => this.handleChangeCheck(deviceMap[i])} />
      deviceMap[i].loan_func = this.changeLoan
      deviceMap[i].inuse_func = this.changeInuse
      deviceMap[i].lat = 0
      deviceMap[i].lon = 0
    }

    //state
    this.state = {
      columuns : columuns,
      style    : style,
      deviceMap: deviceMap,
    }

    //bind functions
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.to_s = this.to_s.bind(this);
    this.onclickgroup = this.onclickgroup.bind(this);
    this.changeLoan = this.changeLoan.bind(this);
    this.changeInuse = this.changeInuse.bind(this);
  }

  componentDidMount() {
    this.checkDevicesInfo();
  }

  //ウインドウサイズの取得
  getWindowSize() {
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    w = w.innerWidth || e.clientWidth || g.clientWidth,
    h = w.innerHeight|| e.clientHeight|| g.clientHeight;
    return {
            width: w,
            height: h
    }
  }

  //貸出・返却
  changeLoan(e){
    let deviceMap = instance.state.deviceMap;
    for(let i in deviceMap){
      if( deviceMap[i].id == e.target.id ){
        deviceMap[i].is_loan = !deviceMap[i].is_loan;
        if(deviceMap[i].is_loan)
          deviceMap[i].searchstr = deviceMap[i].searchstr.replace("返却済", "貸出中")
        else
          deviceMap[i].searchstr = deviceMap[i].searchstr.replace("貸出中", "返却済")
        //REST API
        let onSuccess = function(res){
        }
        let onError = function(){
          alert('react:貸出変更エラー')
        }
        API.changeLoanStatus({id:e.target.id, authenticity_token: instance.props.authenticity_token,
                              onSuccess:onSuccess, onError:onError});
      }
    }
    instance.setState({
      deviceMap: deviceMap.slice()
    });
  }

  //競技用
  changeInuse(e){
    let deviceMap = instance.state.deviceMap;
    for(let i in deviceMap){
      if( deviceMap[i].id == e.target.id ){
        deviceMap[i].in_use = !deviceMap[i].in_use;
        deviceMap[i].athlete_name = ''
        if(deviceMap[i].in_use){
          deviceMap[i].searchstr += " 使用中"
        }
        else
        {
          deviceMap[i].searchstr = deviceMap[i].searchstr.replace("使用中", "")
        }
        //REST API
        let onSuccess = function(res){
        }
        let onError = function(){
          alert('react:競技用デバイス解除エラー')
        }
        API.changeInuseStatus({id:e.target.id, authenticity_token: instance.props.authenticity_token,
                              onSuccess:onSuccess, onError:onError});
      }
    }
    instance.setState({
      deviceMap: deviceMap.slice()
    });
  }


  //グループ変更がおされたら
  onclickgroup(){
    let array = []
    let deviceMap = this.state.deviceMap;
    for(let i in deviceMap){
      if(deviceMap[i].isChecked)
        array.push(deviceMap[i].deviceId)
    }
    let sensors = $("#checked_sensors")
    sensors.val(array.join('\n'))
  }

  //チェックボックス
  handleChangeCheck(d){
    let deviceMap = this.state.deviceMap;
    for(let i in deviceMap){
      if( deviceMap[i].deviceId == d.deviceId ){
        if(d.isChecked){
          deviceMap[i].isChecked = !deviceMap[i].isChecked;
          deviceMap[i].checkbox = <Checkbox ischecked={deviceMap[i].isChecked} handleCheckboxChange={() => this.handleChangeCheck(d)} />
        }
        else{
          deviceMap[i].isChecked = true;
          deviceMap[i].checkbox = <Checkbox ischecked={true} handleCheckboxChange={() => this.handleChangeCheck(d)} />
        }
      }
    }
    instance.setState({
      deviceMap: deviceMap.slice()
    });
  }

  //----------------------------------------------------------

  //デバイスの状態
  checkDevicesInfo(){
    let onSuccess = (res) => {
      let devices_info = res.data.devices;
      let deviceMap = this.state.deviceMap;
      for(let i in deviceMap){
        if( devices_info[deviceMap[i].deviceId] ){
          let d = devices_info[deviceMap[i].deviceId]
          let lastgps    = new Date(Date.parse(d.lastGPS))
          let lastaccess = new Date(Date.parse(d.lastAccess))
          let lastgps_str    = lastgps.getHours()+":"+lastgps.getMinutes()+":"+lastgps.getSeconds()
          let lastaccess_str = lastaccess.getHours()+":"+lastaccess.getMinutes()+":"+lastaccess.getSeconds()
          deviceMap[i].status = "ONLINE"
          deviceMap[i].access = lastaccess_str
          deviceMap[i].fix = lastaccess_str == lastgps_str  ? 1 : 0
          deviceMap[i].gps = lastgps_str

          deviceMap[i].gga = d.gga ? this.to_s(d.gga) : undefined
          if(d.gga)if(d.gga.mode == 2)
            deviceMap[i].fix = 2
          if(d.battery){
            deviceMap[i].battery = d.battery.percentage + "% / " + d.battery.voltage +" V"
            deviceMap[i].battery_p = d.battery.percentage
          }
          else
            deviceMap[i].battery = "-"
          deviceMap[i].device_mode = d.device_mode ? this.to_s(d.device_mode) : undefined

          /*if(d.gga){
            let lat = parseInt(d.gga.lat/100, 10) + (d.gga.lat%100)/60
            let lon = parseInt(d.gga.lon/100, 10) + (d.gga.lon%100)/60
            deviceMap[i].url = "https://www.google.com/maps/search/"+lat+","+lon;
          }*/
					deviceMap[i].lat = d.lat
					deviceMap[i].lon = d.lon
					deviceMap[i].url = "https://www.google.com/maps/search/"+d.lat+","+d.lon;


          //SearchStr
          if(deviceMap[i].status)
            deviceMap[i].searchstr = deviceMap[i].ori_searchstr + " " + "ONLINE"
          if(deviceMap[i].fix)
           deviceMap[i].searchstr = deviceMap[i].searchstr + " " + "fix"
          if(d.gga)if(d.gga.mode == 2)
            deviceMap[i].searchstr = deviceMap[i].searchstr + " " + "D-fix"
        }
        else{
          deviceMap[i].status = undefined;
          deviceMap[i].searchstr = deviceMap[i].ori_searchstr + " " + "OFFLINE"
        }
      }
      instance.setState({
        deviceMap : deviceMap.slice()
      });
    }
    //デバイスステータスを取得
    let intervalId =  setInterval(() => {
        API.getDeviceInfo({ onSuccess: onSuccess, organization_id: this.props.organization_id })
        } ,3000);
    this.setState({ intervalId: intervalId });
  }

  //----------------------------------------------------------
  to_s(json){
    return (<div><pre>{JSON.stringify(json, null, 2) }</pre></div>);
  }

  //EDIT Button
  onEdit(rowID) {
    let deviceMap = instance.state.deviceMap;
    for(let i in deviceMap){
      if(deviceMap[i].id == rowID){
        window.open(deviceMap[i].edit_url);
        return;
      }
    }
  }


  //RENDER
  render(){
    const {deviceMap} = this.state;

    if (this.props.deviceMap.length == 0)
      return "デバイス登録無し";

    //２段目のカスタマイズ表示
    const ExpandableRowComponent = ({ row }) => {
        return (
          <div>
            <a href={row.url? row.url : "no-data"} target="_blank">位置表示</a>
            <p>緯度: {row.lat ? row.lat : "no-data"}</p>
            <p>経度: {row.lon ? row.lon : "no-data"}</p>
            <p>GGA: {row.gga ? row.gga : "no-data"}</p>
            <p>DeviceMode: {row.device_mode ? row.device_mode : "no-data"}</p>
          </div>
        );
    }

    //Actionsボタンカスタマイズ
    const ActionsComponent = ({ row, onAction }) => {
      return (
        <span>
          <button
            type="button"
            className="btn btn-link"
            onClick={e => onAction(e, 'EDIT_ITEM')}
            data-html="true"
            data-toggle="tooltip"
            title="Edit Item"
          >
          <i className="fa fa-pencil" />
          </button>
        </span>
      );
    }

    //新規登録ボタン & グループ変更ボタン
    const Modal = (
        <div>
          {this.props.master &&
          <a type="button" className="btn btn-primary"  href={'/organizations/'+this.props.organization_id+'/sensors/new'}>新規登録</a>
          }
          <button type="button" className="btn btn-info" data-toggle="modal" data-target="#post" onClick={this.onclickgroup}>グループ変更</button>
        </div>
    )


    return (
        <span>
        {Modal}
        <PagiList
                columuns={ this.state.columuns }
                data={ deviceMap }
                itemsPerPage={100}
                ExpandableRowComponent={ExpandableRowComponent}
                options={{
                      searchBox: true,
                      //multipleSelect: true,
                      actionsColumn: true,
                      pagination: true,
                      expandable: true,
                }}
                actionsComponent={ActionsComponent}
                onEdit={this.onEdit}
                customizeField1 = { StatusField }
                onSort={this.onSort}
                use_header_actions={true}
        />
        </span>
    );
  }
}
