import React, { useState, useEffect } from 'react';
import { Select, MenuItem, InputLabel } from '@mui/material';

const DisciplineSelect = ({ disciplines }) => {
  // disciplines[0] を初期値にセット
  const [selectedDiscipline, setSelectedDiscipline] = useState(`discipline_0`);

  const handleChange = (event) => {
    setSelectedDiscipline(event.target.value);
    updateDisplay(event.target.value);
  };

  // 初期表示時に0番目のdisciplineを表示
  useEffect(() => {
    updateDisplay('discipline_0');
  }, []);

  // 選択されたdisciplineの表示を切り替える関数
  const updateDisplay = (disciplineId) => {
    const allDisciplines = document.querySelectorAll('#races > div');
    allDisciplines.forEach((discipline) => {
      discipline.style.display = 'none'; // 全て非表示にする
    });

    if (disciplineId) {
      document.getElementById(disciplineId).style.display = 'block'; // 選択されたdisciplineのみ表示
    }
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <InputLabel id="discipline-select-label">種目を選択</InputLabel>
      <Select
        labelId="discipline-select-label"
        id="disciplineSelect"
        value={selectedDiscipline}
        onChange={handleChange}
        style={{ width: '200px' }}
      >
        {disciplines.map((discipline, index) => (
          <MenuItem key={index} value={`discipline_${index}`}>
            {discipline.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DisciplineSelect;

