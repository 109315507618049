import React from "react"
import PropTypes from "prop-types"
import MapBrowser from "../map/MapBrowser"
import API from "../API"
import R from "./ReplayData"
import I from "./initialize_replay"
import Slider from "./Slider"
import DeviceList from '../device_list/DeviceList'
import '!style-loader!css-loader!./ReportMain.css'; 
import '!style-loader!css-loader!font-awesome/css/font-awesome.min.css';
import { mapType } from '../map/MapBrowser'
import pako from 'pako';
import axios from 'axios';

var this_obj = null;
var replayData = null;
var timer = null;
let listChange = 0;
let defaultMapCenter = null;

export default class ReportMain extends React.Component {

  constructor(props) {
    super(props);

    //Initialize device group visible
    let deviceGroups = [];
    let noGroup = {id: -1, group_name: "No Group", visible: true, count: this.props.deviceGroupsCount[""]};
    deviceGroups.push(noGroup);
    for(let i in this.props.deviceGroups){
      let dg = this.props.deviceGroups[i];
      dg.visible = true;

      if(this.props.deviceGroupsCount[dg.id]){
        dg.count = this.props.deviceGroupsCount[dg.id];
      }else{
        dg.count = 0;
      }
      deviceGroups.push(dg);
    }

    this.state = {
      lines: [],
      circles: [],
      polygons: [],
      vbuoys: [],
      geojsons: [],
      dataRange: { min: 0,
                   max: 100,
                   step: 1 },
      now_time: [],
      deviceMap: [],
      show_label: true,
      show_line: true,
      knot: false,
      max_line_length: 30,
      measure_distance: false,
      deviceGroups: deviceGroups,
      increment_second: 5,
      play_state: true,
      mapType: mapType.osmBlackAndWhite,
      mapCenter: null, // マップの初期表示後は、マップ中心(mapCenter)を変更してもブラウザの表示位置は移動しない。
                       // そのため、デフォルトをnullとし、値がセットされるまではマップを表示しない仕様とする。
    }
    this_obj = this;
    defaultMapCenter = this.props.center;
    this.MapBrowserRef = React.createRef();
    this.handleSelectDataRange = this.handleSelectDataRange.bind(this);
    this.handleShowLabel = this.handleShowLabel.bind(this);
    this.handleMeasureDistance = this.handleMeasureDistance.bind(this);
    this.handleUpdateDeviceMap = this.handleUpdateDeviceMap.bind(this);
    this.handleChangeMaxLength = this.handleChangeMaxLength.bind(this);
    this.handleChangeKnot = this.handleChangeKnot.bind(this);
    this.toggleMeasureDistance = this.toggleMeasureDistance.bind(this);
    this.setMapType = this.setMapType.bind(this);
  }

  componentDidMount() {
    let onSuccess = (data) => {
      let replay = new R.Init(data);
			console.log(replay)
      replayData = replay;
      I.initializeSlider(this_obj, replay);
      this_obj.setState({
        deviceMap: replay.replayDevices,
        originalMap: replay.replayDevices,
        lines:    replay.geofences.lines    ? replay.geofences.lines : [],
        circles:  replay.geofences.circles  ? replay.geofences.circles : [],
        polygons: replay.geofences.polygons ? replay.geofences.polygons : [],
        vbuoys:   replay.geofences.vboys    ? replay.geofences.vbuoys : [],
        geojsons: replay.geofences.geojsons ? replay.geofences.geojsons : [],
        mapCenter: replay.map_center ? replay.map_center : defaultMapCenter,
      });
      timer = this.autoPlay(this_obj, replay);
    };
    let onError = (error) => {
      console.log(error);
      this_obj.setState({
        mapCenter: defaultMapCenter,
      });
    };


    // データを取得する関数
    const fetchData = async () => {
      try {
        // Gzip圧縮されたファイルを取得
        const response = await axios.get(this.props.json_url, {
          responseType: 'arraybuffer', // ArrayBufferとしてレスポンスを取得
        });

        // ArrayBufferをUint8Arrayに変換
        const buffer = new Uint8Array(response.data);

        // Gzipのマジックナンバーをチェック
        const isGzip = buffer[0] === 0x1f && buffer[1] === 0x8b;

        if (isGzip) {
          // Gzipデータの場合、解凍処理を行う
          const decompressedData = pako.inflate(buffer, { to: 'string' });
          const jsonData = JSON.parse(decompressedData);
					onSuccess(jsonData);
        } else {
          // 通常のJSONデータの場合、そのまま読み込む
          const jsonData = JSON.parse(new TextDecoder().decode(buffer));
					onSuccess(jsonData);
        }
      } catch (err) {
				onError(err);
      }
    };
    fetchData();
  }

  componentWillUnmount(){
    clearTimeout(timer);
  }

  componentDidUpdate(){
    // Statusの値を持つDeviceを前にソートする
    let sorted_deviceMap = [];
    let n_gps_device = 0;
    for(let i in this.state.deviceMap){
      let device = this.state.deviceMap[i];
      if(device.status){
        sorted_deviceMap.splice( n_gps_device, 0, device );
        n_gps_device++;
      } else {
        sorted_deviceMap.push(device);
      }
    }

    // DeviceMapのidの順番を比較して、変化があった場合setStateでdeviceMapを更新
    let pre_idList = this.state.deviceMap.map(function(item){
      return item.id;
    });
    let new_idList = sorted_deviceMap.map(function(item){
      return item.id;
    });

    if(pre_idList.toString() != new_idList.toString()){
      this_obj.setState({
        deviceMap: sorted_deviceMap
      });
      // DeviceList強制更新用にインクリメントする
      listChange++;
    }
  }



  //時間バーを変更
  handleSelectDataRange = (ui) =>{
    var time = new Date(ui[0]*1000);

    //現在時間を与え、deviceMapを更新
    replayData.move(time);

    this.setState({
      now_time: ui,
      deviceMap: this.state.deviceMap.slice(),
    });
  }

  handleShowLabel(){
    this_obj.setState({
      show_label: !this_obj.state.show_label,
    });
  }

  handleShowLine(){
    this_obj.setState({
      show_line: !this_obj.state.show_line,
    });
  }

  handleChangeKnot(){
    this.setState({
      knot: !this.state.knot,
    });
  }

  handleMeasureDistance(){
    this_obj.toggleMeasureDistance();
    this_obj.MapBrowserRef.current.initShowDistanceDevices();
  }

  toggleMeasureDistance(){
    this_obj.setState({
      measure_distance: !this_obj.state.measure_distance,
    });
  }

  //子コンポーネントから呼び出される更新関数
  handleUpdateDeviceMap( newDeviceGroups, newMap){
    this.setState({
        deviceGroups: newDeviceGroups,
        deviceMap: newMap,
    });
  }

  //子コンポーネントから呼び出される更新関数（max_line_length)
  handleChangeMaxLength(val){
    this_obj.setState({
      max_line_length: val,
    })
  }


  //オートプレイ
  autoPlay(t, replay){

    function slide(){
      //現在の時間
      let current = t.state.now_time[0]
      let next = current + Number(t.state.increment_second)
      //時間を進める
      t.setState({
        now_time: [next]
      });
      let time = new Date((next)*1000);
      //動かす
      replay.move(time)
    }
    var timer = setInterval(slide, 1000);
    return timer;
  }

  //カウントアップ倍率の変更
  handleChangeIncrementSecond(e){
    this_obj.setState({
      increment_second: e.target.value
      });
  }

  //再生or停止
  handleChangePlayState(){
    if(this_obj.state.play_state){
      //停止
      clearTimeout(timer);
      this_obj.setState({
        play_state : false
      });
    } else {
      this_obj.setState({
        play_state : true
      });
      timer = this_obj.autoPlay(this_obj, replayData);
    }
  }

  setMapType(type){
    this_obj.setState({
      mapType: type
    });
  }

  render () {
    const {deviceMap, originalMap} = this.state;
    // listChangeが変化するとDeviceListは強制的に更新される
    const deviceList =  <DeviceList deviceMap={deviceMap}
                                    originalMap={originalMap}
                                    deviceGroups={this.state.deviceGroups}
                                    handleUpdateDeviceMap={this.handleUpdateDeviceMap}
                                    key={listChange}
                                    handleShowLabel={this.handleShowLabel}
                                    handleShowLine={this.handleShowLine}
                                    show_label={this.state.show_label}
                                    show_line={this.state.show_line}
                                    knot={this.state.knot}
                                    handleChangeKnot={this.handleChangeKnot}
                                    measure_distance={this.state.measure_distance}
                                    max_line_length={this.state.max_line_length}
                                    handleChangeMaxLength={this.handleChangeMaxLength}
                                    handleMeasureDistance={this.handleMeasureDistance}
                                    toggleMeasureDistance={this.toggleMeasureDistance}
                                    setMapType={this.setMapType}
                                    />

    if (this.state.mapCenter == null) {
      return ""
    }

    return (
      <div className="report">
        <div className="col-md-12 col-xs-12">
          <MapBrowser ref={this.MapBrowserRef}
                      center={this.state.mapCenter} zoom={14} handleClick={this.handleClick} height={'86vh'}
                      lines={this.state.lines}
                      circles={this.state.circles}
                      polygons={this.state.polygons}
                      vbuoys={this.state.vbuoys}
                      geojsons={this.state.geojsons}
                      emergencies={[]}
                      deviceMap={this.state.deviceMap}
                      topright={deviceList}
                      show_label={this.state.show_label}
                      measure_distance={this.state.measure_distance}
                      knot={this.state.knot}
                      show_line={this.state.show_line}
                      max_line_length={this.state.max_line_length}
                      mapType={this.state.mapType}
          />
          <Slider
            dataRange={this.state.dataRange}
            values={this.state.now_time}
            handleSelectDataRange={this.handleSelectDataRange}
            />
          <div className={'col-sm-2 nowtime'}>{I.formatDT(this.state.now_time)}</div>
          <div className={'col-sm-3'}></div>
          <div className={'col-sm-2'}>
            <select className={'play_speed'} onChange={this.handleChangeIncrementSecond} defaultValue="5">
              <option value="1">x1</option>
              <option value="5">x5</option>
              <option value="10">x10</option>
              <option value="60">x60</option>
              <option value="120">x120</option>
              <option value="300">x300</option>
            </select>
            {this.state.play_state
              ?<button><span className="fas fa-pause" onClick={this.handleChangePlayState}></span></button>
              :<button><span className="fas fa-play"  onClick={this.handleChangePlayState}></span></button>
            }
          </div>
          <div className={'col-sm-3'}></div>
          <div className={'col-sm-2 endtime'}>{I.formatDT(this.state.dataRange.max)}</div>
        </div>
      </div>
    );
  }
}
